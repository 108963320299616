<template>
    <div class="forum-container">
      <h1>Forum</h1>
      <div class="entry-form">
        <input type="text" v-model="entryName" placeholder="Entry Name" required />
        <select v-model="entryCategory">
          <option v-for="category in categories" :key="category" :value="category">{{ category }}</option>
        </select>
        <textarea v-model="entryDescription" placeholder="Description" required></textarea>
        <button @click="addEntry">Add Entry</button>
      </div>
      <div class="entry-list">
        <div v-for="entry in entries" :key="entry.id" class="entry">
          <h2>{{ entry.name }}</h2>
          <p>{{ entry.description }}</p>
          <button @click="upvote(entry)">Upvote</button>
          <button @click="downvote(entry)">Downvote</button>
          <p>Score: {{ entry.score }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ForumPage',
    data() {
      return {
        entryName: '',
        entryCategory: '',
        entryDescription: '',
        entries: [],
        categories: ['Category 1', 'Category 2', 'Category 3']
      };
    },
    methods: {
      addEntry() {
        const newEntry = {
          id: Date.now(),
          name: this.entryName,
          category: this.entryCategory,
          description: this.entryDescription,
          score: 0
        };
        this.entries.push(newEntry);
        this.entryName = '';
        this.entryCategory = '';
        this.entryDescription = '';
      },
      upvote(entry) {
        entry.score += 1;
      },
      downvote(entry) {
        entry.score -= 1;
      }
    }
  };
  </script>
  
  <style scoped>
  .forum-container {
    padding: 20px;
    background-color: #333;
    color: #e6e6e6;
  }
  .entry-form {
    margin-bottom: 20px;
  }
  .entry-list .entry {
    margin-bottom: 20px;
  }
  </style>
  