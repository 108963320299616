<template>
  <div class="success-container">
    <h1>Formulario enviado con éxito.</h1>
  </div>
</template>

<script>
export default {
  name: 'SuccessPage'
};
</script>

<style scoped>
.success-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #e6e6e6;
  font-family: 'Open Sans', sans-serif;
  font-size: 70%;
  background-color: #333333;
}

@media (max-width: 768px) {
  .success-container {
    max-width: 60%; /* Adjust width for mobile devices */
    padding-left:20%;
    padding-right:20%;
    margin-left: 6%;
    margin-top: -6%;
    margin-bottom: -6%;
  }
}
</style>
