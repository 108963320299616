import { createRouter, createWebHistory } from 'vue-router';
import RegistrationForm from '../components/RegistrationForm.vue';
import SuccessPage from '../components/SuccessPage.vue';
import LoginPage from '../components/LoginPage.vue';
import ForumPage from '../components/ForumPage.vue';
import TermsAndConditions from '../components/TermsAndConditions.vue';
import SearchPage from '../components/SearchPage.vue'; // Import the new component

const routes = [
  {
    path: '/',
    name: 'RegistrationForm',
    component: RegistrationForm,
  },
  {
    path: '/success',
    name: 'SuccessPage',
    component: SuccessPage,
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
  },
  {
    path: '/forum',
    name: 'ForumPage',
    component: ForumPage,
  },
  {
    path: '/terms',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
  },
  {
    path: '/search', // Add the new route
    name: 'SearchPage',
    component: SearchPage,
    meta: { requiresAuth: true }, // Add meta field to indicate that this route requires authentication
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next('/login');
  } else {
    next();
  }
});

export default router;
