<template>
    <div class="terms-container">
      <h1>Términos y Condiciones</h1>
      <div class="terms-content">
        <p>Estos son los términos y condiciones...</p>
        <!-- Add more terms and conditions content here -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TermsAndConditions',
  };
  </script>
  
  <style scoped>
  .terms-container {
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    max-width: 800px;
    margin: 2rem auto;
  }
  
  .terms-content {
    color: #333;
    line-height: 1.6;
  }
  </style>
  