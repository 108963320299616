<template>
  <div id="app">
    <div class="app-container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
}

.app-container {
  width: 100%;
  max-width: 100%;
  padding-top: 20px;
}

@media (max-width: 768px) {
  
  .registration-form {
    width: 100%; /* Adjust width for mobile devices */
    margin-left: %;
  }
  
  .app-container {
    width: 100%; /* Adjust width for mobile devices */
    margin-left: -7%;
  }
  .form-title {
    
  }

}
</style>


