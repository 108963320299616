<template>
    <div class="search-container">
      <h1>Search Page</h1>
      <div class="filter-container">
        <input v-model="filter" placeholder="Buscar" @input="updateTable" />
      </div>
      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Apellidos</th>
            <th>DNI</th>
            <th>Teléfono</th>
            <th>Email</th>
            <th>
              <div class="custom-select-wrapper wide" :class="{ open: dropdownOpenProvincias }" ref="provinciasDropdown">
                <div class="custom-select" @click="toggleDropdown('provincias')">
                  Provincias
                  <span class="arrow" :class="{ open: dropdownOpenProvincias }">&#9662;</span>
                </div>
                <div class="custom-select-options" v-if="dropdownOpenProvincias">
                  <div v-for="option in provinces" :key="option">
                    <label>
                      <input 
                        type="checkbox" 
                        :value="option" 
                        v-model="selectedProvincias"
                        @change="updateTable" 
                      />
                      {{ option }}
                    </label>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div class="custom-select-wrapper wide" :class="{ open: dropdownOpenCargos }" ref="cargosDropdown">
                <div class="custom-select" @click="toggleDropdown('cargos')">
                  Cargos
                  <span class="arrow" :class="{ open: dropdownOpenCargos }">&#9662;</span>
                </div>
                <div class="custom-select-options" v-if="dropdownOpenCargos">
                  <template v-for="(group, groupName) in groupedCargos" :key="groupName">
                    <div class="category">{{ groupName }}</div>
                    <div v-for="option in group" :key="option" class="option-label">
                      <label>
                        <input 
                          type="checkbox" 
                          :value="option" 
                          v-model="selectedCargos"
                          @change="updateTable" 
                        />
                        {{ option }}
                      </label>
                    </div>
                  </template>
                </div>
              </div>
            </th>
            <th>Notas adicionales</th>
            <th>Videobook</th>
            <th>Curriculum</th>
            <!-- Add more columns as needed -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="submission in filteredSubmissions" :key="submission.id">
            <td>{{ submission.nombre }}</td>
            <td>{{ submission.apellidos }}</td>
            <td>{{ submission.dni }}</td>
            <td>{{ submission.telefono }}</td>
            <td>{{ submission.email }}</td>
            <td>{{ submission.provincias }}</td>
            <td>{{ submission.cargos }}</td>
            <td>{{ submission.additionalNotes }}</td>
            <td>{{ submission.videobook }}</td>
            <td><a :href="`/uploads/${submission.curriculum}`" download>Download</a></td>
            <!-- Add more columns as needed -->
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SearchPage',
    data() {
      return {
        submissions: [],
        filter: '',
        dropdownOpenProvincias: false,
        dropdownOpenCargos: false,
        selectedProvincias: [],
        selectedCargos: [],
        provinces: [
          "A Coruña", "Álava", "Albacete", "Alicante", "Almería", "Asturias", "Barcelona", "Cádiz", "Cantabria", "Castellón", "Ceuta", "Ciudad-Real", 
          "Córdoba", "Cuenca", "Extremadura", "Girona", "Granada", "Guadalajara", "Guipúzcoa", "Huelva", "Huesca", "Jaén", "Las Palmas de Gran Canaria", 
          "León", "Lleida", "Logroño", "Lugo", "Madrid", "Melilla", "Murcia", "Navarra", "Ourense", "Pontevedra", "Salamanca", "Santa Cruz de Tenerife", 
          "Sevilla", "Soria", "Tarragona", "Teruel", "Toledo", "Valencia", "Valladolid", "Vizcaya", "Zamora", "Zaragoza"
        ],
        categorias: {
          "Interpretación": ["Actor o actriz", "Extras y figurantes", "Especialistas", "Actor o actriz de voz"],
          "Producción": ["Productor", "Productor ejecutivo", "Productor de campo", "Auxiliares y meritorios"],
          "Dirección": ["Director", "Ayudante de dirección", "Script o continuista", "Director de casting"],
          "Fotografía": ["Director de fotografía", "Cámara", "Foquista", "Auxiliares y ayudantes de cámara", "Gaffer / jefe de eléctricos", "Eléctricos", "Foto fija", "Grip / maquinista", "Colorista"],
          "Sonido": ["Operador de dron", "Pertiguista", "Jefe de sonido", "Diseñador de sonido", "Mezclador", "Grabador de foley", "Editor de diálogos"],
          "Arte": ["Director de arte", "Ambientador", "Escenógrafo"],
          "Vestuario": ["Figurinista", "Sastre", "Asistente de vestuario"],
          "Maquillaje": ["Caracterizador", "Maquillador", "Peluquero"],
          "Edición": ["Montador", "Ayudantes y asistentes", "Subtitulado", "Créditos"],
          "Otros": ["VFX", "Distribuidor", "Marketing y publicidad", "Exhibidor", "Filmmaker para RRSS", "Filmmaker de making of", "Cartelería"]
        }
      };
    },
    computed: {
      groupedCargos() {
        return this.categorias;
      },
      filteredSubmissions() {
        return this.submissions.filter(submission => {
          const filterTextMatch = Object.keys(submission).some(key => {
            return String(submission[key]).toLowerCase().includes(this.filter.toLowerCase());
          });
          const provinciasMatch = this.selectedProvincias.length === 0 || this.selectedProvincias.some(provincia => submission.provincias.includes(provincia));
          const cargosMatch = this.selectedCargos.length === 0 || this.selectedCargos.some(cargo => submission.cargos.includes(cargo));
          return filterTextMatch && provinciasMatch && cargosMatch;
        });
      }
    },
    mounted() {
      this.fetchSubmissions();
      document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
      document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
      async fetchSubmissions() {
        try {
          const response = await fetch('/fetch_submissions.php'); // Adjust this path
          const data = await response.json();
          this.submissions = data;
        } catch (error) {
          console.error('Error fetching submissions:', error);
        }
      },
      toggleDropdown(dropdown) {
        if (dropdown === 'provincias') {
          this.dropdownOpenProvincias = !this.dropdownOpenProvincias;
          if (this.dropdownOpenProvincias) {
            this.dropdownOpenCargos = false;
          }
        } else if (dropdown === 'cargos') {
          this.dropdownOpenCargos = !this.dropdownOpenCargos;
          if (this.dropdownOpenCargos) {
            this.dropdownOpenProvincias = false;
          }
        }
      },
      handleClickOutside(event) {
        if (!this.$refs.provinciasDropdown.contains(event.target) && !this.$refs.cargosDropdown.contains(event.target)) {
          this.dropdownOpenProvincias = false;
          this.dropdownOpenCargos = false;
        }
      },
      updateTable() {
        // The computed property filteredSubmissions will automatically update
        this.$forceUpdate(); // Force Vue to re-render the component
      }
    }
  };
  </script>
  
  <style scoped>
  .search-container {
    padding: 2rem;
  }
  
  .filter-container {
    margin-bottom: 1rem;
  }
  
  .custom-select-wrapper {
    position: relative;
    width: 100%;
  }
  
  .custom-select-wrapper.wide {
    min-width: 200px; /* Adjust this value as needed */
  }
  
  .custom-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #000000; /*Background color custom fields*/
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    color: #e6e6e6; /* Custom select text color */
  }
  
  .custom-select .arrow {
    padding-left: 10px;
    transition: transform 0.3s ease;
  }
  
  .custom-select .arrow.open {
    transform: rotate(180deg);
  }
  
  .custom-select-options {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #000000;
    border: 1px solid #ccc;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    z-index: 10;
    max-height: 260px;
    overflow-y: auto;
    width: 100%;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    color: #e6e6e6; /* Custom select options color */
  }
  
  .custom-select-wrapper.open .custom-select-options {
    display: block;
  }
  
  .custom-select-options label, .custom-select-options .category {
    display: flex;
    align-items: flex-start; /* Aligns items to the top */
    padding: 8px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
  }
  
  .custom-select-options label.active {
    background-color: #E57373; /* Light red background for active options */
    color: white;
  }
  
  .custom-select-options input[type="checkbox"] {
    margin-right: 7px;
  }
  
  .category {
    font-weight: bold;
    border-top: 1px solid #ccc;
    margin-top: 8px;
    padding-top: 8px;
  }
  
  .category:first-of-type {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    color: white;
  }
  
  th, td {
    padding: 0.5rem;
    border: 1px solid #ccc;
  }
  
  th {
    background-color: #000000;
    color: #ffffff;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  </style>
  